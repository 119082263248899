import React from "react"
import {
  Layout,
  FlexCol,
  Parallax,
  NewsletterSection,
} from "@components/layout"
import { Posts, CategoryItem, BlogItem } from "@components/blog"
import { Breadcrumbs } from "@components/shared"
import { withHtml } from "@components/logic"

import { blog__wave, blog__categoryItem__wrap } from "./styles/blog.module.scss"

const Categories = ({ categories = [], posts = [] }) => {
  const shuffledCategories = categories.sort(() => Math.random() - 0.5)
  // const categoryPosts = []
  // const categoryPosts = posts.filter((el) => el.categories.nodes[0].slug == 'gdybania-seniora')

  return (
    <React.Fragment>
      <h2>Co można tutaj poczytać?</h2>
      <div className={blog__categoryItem__wrap}>
        {shuffledCategories
          .filter((el) => el.posts.nodes.length > 0)
          .map((el, k) => (
            <CategoryItem {...el} allPosts={posts} key={k} />
          ))}
      </div>
    </React.Fragment>
  )
}

const Wave = () => {
  return (
    <div className={blog__wave}>
      <svg viewBox="0 0 1200 600">
        <path
          d="M 0 600 Q 130 280 600 500 Q 1175 710 1200 190 L 1200 600 "
          transform="translate(0 0)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}

const Title = withHtml("h1")

const Blog = ({ pageContext }) => {
  const { posts, page, categories } = pageContext
  return (
    <Layout {...page}>
      <Parallax height={500} image={page.featuredImage}>
        <Title>Blog z przemyśleniami programisty i mentora</Title>
        <Wave />
      </Parallax>
      <Breadcrumbs
        elements={[
          {
            label: page.title,
            url: `/blog/`,
          },
        ]}
      />
      <Categories categories={categories} posts={posts} />
      <NewsletterSection />
      <Posts
        title={"Każdy artykuł ma coś w sobie"}
        posts={posts}
        recommended={true}
      />

      {/* <h1>Wszystkie posty w paginacji wg daty (20 na stronie):</h1> */}
      {/* <Posts posts={posts} /> */}

      {/*
			<List>
				<FlexRow>
					<Parallax height={300} image={page.featuredImage}>
						<h1 dangerouslySetInnerHTML={{__html:page.title}}></h1>
					</Parallax>
				
					{posts.map((post, key) => {
						return <BlogItem {...post} key={key} />
					})}
				</FlexRow>
				<NewsletterSection />
			</List>
			*/}
    </Layout>
  )
}

export default Blog
